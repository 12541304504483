@font-face {
    font-family: Poppins_bold;
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
    font-family: Poppins_Regular;
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
body{
  position: fixed;
}
.bold_poppins {
  font-family: Poppins_bold;
}
.regular_poppins {
  font-family: Poppins_regular;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1297px) {
  .big_title {
    font-size: 25px !important; /* Adjust the font size for mobile view */
  }
}
@media only screen and (max-width: 1237px) {
  legend{
    white-space: nowrap !important;
        font-size: 1em !important;
        background-color: #ffffff !important;
       
      }
}
@media only screen and (max-width: 1070px) {
  
  .dashboard_map svg{
    width: 800px !important;
    height: 500px !important;
  }
 
  .div_faMapPin_dash .faMapPin_dash{
    width: 10px !important;
    height: 15px !important;
  }
  
}

@media only screen and (max-width: 1023px) {
  .desc_tumbuhan{
    display: none !important;
  }
  .img_view{
    width: 40px !important;
    margin-top: 0px !important;
  }
  .listBar{
    align-items: center;
    justify-content: start;
  }
}

@media only screen and (max-width: 992px) {
  
  .slider_img{
    width: 200px !important;
  }
  .view_details{
    margin-top: 7% !important;
  }
 
  
}
@media only screen and (max-width: 913px) {
  .big_title {
    font-size: 20px !important; /* Adjust the font size for mobile view */
  }
}
@media only screen and (max-width: 830px) {
  
  .dashboard_map svg{
    max-width: 650px;
    height: 350px !important;
    margin-left: 10% !important;
  }

  .div_faMapPin_dash .faMapPin_dash{
    width: 5px !important;
    height: 15px !important;
  }
  .div_faMapPin_dash{
   font-size: 8px !important;
  }

  .dash_blue_pointer{
    width: 80px !important;
    height: 25px !important;
    margin-top: -10px !important;
    margin-left: -5px !important;
  }

  .image-container {
    width: 350px !important;
  }
  
}
@media only screen and (max-width: 768px) {
  .login_leftCard {
    display: none !important; /* Adjust the font size for mobile view */
  }

  .image-container {
    width: 500px !important;
    margin-left: 20px;
  }

  .text_left{
    text-align: left !important;
  }

}

@media only screen and (max-width: 760px) {
  /* .dropdown, .w3-sidebar  {
    display: none !important;
  } */
  #mySidebar2{
    display: block !important;
  }
  #mySidebar{
    display: none !important;
  }
  .logout_btn{
    display: block !important;
  }
}

@media only screen and (max-width: 759px) {
  .big_title {
    font-size: 15px !important; /* Adjust the font size for mobile view */
  }
  .dropdown-toggle{
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 702px) {
  
  .dashboard_map{
    max-width: 590px;
  }
  
}
@media only screen and (max-width: 606px) {
  
  .navBtn{
    display: none !important;
  }
  .big_title{
    font-size: 14px !important;
  }
  .dashboard_map{
    max-width: 500px !important;
  }
  legend{
white-space: nowrap !important;
    font-size: 1em !important;
  }
  .dropdown-toggle{
    font-size: 15px !important;
  }
  .unwrap{
    white-space: nowrap !important;
    max-width: 200px;
    overflow:hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 520px) {
  
  .dashboard_map svg{
    max-width: 450px !important;
  }
  .image-container {
    width: 350px !important;
  }
  
}

@media only screen and (max-width: 500px) {
  .big_title {
    font-size: 15px !important;
    text-align: center;
  }
  .dropdown-toggle{
    font-size: 13px !important;
  }
  .nama_item{
    font-size: 15px !important;
  }
  .unwrap{
    white-space: nowrap !important;
    max-width: 150px;
    overflow:hidden;
  }
  .img_view{
    min-width: 30px !important;
  }
  
}
@media only screen and (max-width: 452px) {
  
  .top_bar img{
    height: 35px !important;
    width: 35px !important;
  }
  .dashboard_map svg{
    max-width: 350px !important;
    margin-top: -10% !important;
  }
  legend{
   margin-top: 1px !important;
    white-space: nowrap !important;
        font-size: 15px !important;
      }

      .pg_title{
        font-size: 15px !important;
      }

      .dash_blue_pointer{
        width: 60px !important;
        height: 30px !important;
        margin-top: -15px !important;
        margin-left: -10px !important;
      }

      .div_faMapPin_dash{
        font-size: 7px !important;
       }

       .div_faMapPin_dash .faMapPin_dash{
        width: 6px !important;
        height: 25px !important;
      }
  
}
@media only screen and (max-width: 400px) {
  .addZone  {
  
   white-space: nowrap;
  }

  .zone_selector{
    margin-bottom: 10px !important;
  }

  .image-container {
    width: 250px !important;
  }

  
}



@media only screen and (max-width: 370px) {
  .unwrap{
    white-space: nowrap !important;
    max-width: 80px;
    overflow:hidden;
  }

  .dashboard_map svg{
    max-width: 300px !important;
    margin-top: -20% !important;
  }

  legend{
    margin-top: 1px !important;
     white-space: nowrap !important;
         font-size: 10px !important;
       }

       .div_faMapPin_dash .faMapPin_dash{
        width: 6px !important;
        height: 35px !important;
      }
  
}
.nowrap{
  white-space: nowrap !important;
}






/* @media only screen and (max-width: 420px) {
  
  .dashboard_map{
    max-width: 350px !important;
  }
  
} */
/* @media only screen and (max-width: 600px) {
  
  canvas{
    height: 60% !important;
    width: 60% !important;
  }
  
} */
canvas{
  height: 60% !important;
  width: 60% !important;
}

/* sidebar */
.sidebar_dashboard{
  
}
.sidebar_title {
  color: #f03b22;
  font-family: poppins_bold;
  font-size: 20px;
  margin-right: 1%;
  text-align: center;
}
.nav_dahsboard{
  background-color: #fff !important;
}
.nav_title{
  color: #37A37C !important;
  padding: 3px !important;
}
.sub_nav{
  color: black !important;
}
/* sidebar */

/* .base_layout{
  margin-left: 2.5%;
  margin-right: 2.5%;
} */
.qrPage{
  /* height: 100vh;
  background-color: #F7FFEF; */
}


.login_img {
  min-height: 550px;

}
.mardi_logo{
  width: 25%;
}

.mardi_logo_layout{
  width: 6.5%;
  margin-left: 2%;
}

.big_title{
  margin-left: 2%;
  font-family: poppins_bold;
  text-shadow: 0px 4px 3px #bcbcbc;
  font-size: 2.5em;
  letter-spacing: 3px;
}

.top_bar{
  align-items: center;
  justify-content: start;
}

.card {
  max-width: 75%; /* or a specific value like 300px, etc. */
  border: 2px solid #AFACAC !important;
}
fieldset {
  border: 2px solid #AFACAC !important;
  padding: 10px !important;
}

/* .login_legend{
  min-height: 440px;
  margin-top: 20px;
} */

legend{
  background-color: #ffffff;
  color: #5E4D3F;
  margin: 5px;
  max-width: 30%;
  text-align: center;
  margin-top: -30px;
  font-weight: bold;
  font-family: Poppins_Bold;
}

.login_label{
  color: #7C6A59;
  font-family: Poppins_Regular;
  font-weight: bold;
}


.login_input .form-control {
  background-color: #3A7560; 
  color: #fff; 
  
}
.login_input input:focus {
  background-color: #3A7560; 
  color: #fff; 
  outline: none;
  box-shadow: none;
}

.login_input span{
  background-color:  #3A7560 ;
  color: #fff;
}

.login_input::placeholder {
    color: #fff !important;
    font-family: Poppins_Regular;
    font-size: 0.8em;
}

button.login_btn{
  background-color: #F03B22;
  font-family: Poppins_Regular;
}

.copyright{
font-family: Poppins_Regular;
}

/* .navbar_layout{
  margin-left: 2%;
} */
.navBar{
  text-decoration: none;
  margin-right:30px;
  font-size:1rem;
  font-family: Poppins_regular;
  font-weight: bold;
  letter-spacing: 2px;
}

.navBtn a.active{
  color: #4BB790 !important;
}

.navBtn a, .navBar a{
  float: left;
  color: #f7ffef;
  text-align: center;
  text-decoration: none;
  font-family: poppins_regular;
  text-shadow: 3px 4px 6px #272727;
}

.navBtn{
  margin: 10px;
}

.welcome_nav{
  background-color: #3A7560;
  margin: 10px;
  border-radius: 5px;
}

.welcome_text{
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Poppins_regular;
  font-weight:bold;
  font-size: 1rem;
  
}

.topBar{
  position: fixed;
}

.formSimpanBtn{
  background-color: #6a7c59 !important;
  color: #fff !important;
  width: 150px;
  margin-right: 5%;
  height: 32px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: poppins_regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  
}

.formBatalBtn {
  background-color: #eb2323 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: poppins_regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;

}
.formKembaliBtn {
  background-color: #59B2B2 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: poppins_regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;

}

.btnKembali {
  background-color: #900103 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: poppins_regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;

}

.qrGenBtn{
  background-color: #F03B22 !important;
  color: #fff !important;
  width: 150px;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: poppins_regular;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 4px;
  height: 32px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.label_control{
  color: #37a37c;
    font-family: poppins_regular;
}

.sidebar_header{
  background-color: #6A7C59;
  padding: 5%;
  padding-top: 10%;
  padding-bottom: 10%;
  height: 30%;
}

.sidebar_headerqr{
  background-color: #4BB790;
  /* padding: 5%; */
  padding-top: 10%;
  padding-bottom: 10%;
  height: 30%;
}
.sidebar_bodyqr{
  background-color: #4BB790;
  padding: 5%;
}
.sidebar_body{
  padding: 5%;
}

.sidebar ul li a{
 color: white !important;
 text-shadow: 0px 4px 3px #515151;
}
.sidebarqr{
  background-color: #4BB790;
}

.sidebar_scroll{
  overflow-y: auto;
  max-height: 240px;
}
.sidebar_scroll2 div a{
  font-size: large;
  text-shadow: 0px 2px 2px #515151;
  padding: 5%;
}
.sidebar_scroll2 div a:hover{
 color: #d7d7d7 !important;
}

.side_body{
  min-height: 75% !important;
}

.qr_icon{
 width: 20px;
}
.qr_icon2{
 width: 40px;
}
.img_view{
  width: 150px;
}
.img_view2{
  width: 95%;
}
.label_view{
  color: #37A37C;
}
.qrGenerated{
  width: 200px;
}
.qrGen{
  color: black !important;
  background-color: black !important;
  width: 50%;
}
.card_qr{
 width: 100%;
}
.saveQR_btn {
  background-color: #4bb790;
  color: #000;
  font-family: poppins_regular;
  width: 150px;
  margin-right: 5%;
  margin-bottom: 5%;
  box-shadow: 3px 2px 5px #a1a1a1;
  border-radius: 4px;
  font-family: poppins_regular;
  font-weight: 600;
  letter-spacing: 2px;
  height: 32px;
  border: none;
  outline: none;
}

.printQR_btn {
  background-color: #f03b22;
  color: #000;
  font-family: poppins_regular;
  width: 150px;
  margin-right: 5%;
  margin-bottom: 5%;
  box-shadow: 3px 2px 5px #a1a1a1;
  font-family: poppins_regular;
  font-weight: 600;
  border-radius: 4px;
  height: 32px;
  letter-spacing: 2px;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.form_btn {
    justify-content: center;
    margin-left: 5%;
}

.custom-dropdown-menu::before {
  display: none;
}
.dropdown button{
  border-radius: 0%;
  margin: 0 !important;
  height: 50px;
  font-size: 17px;
  font-family: Poppins_Regular;
  text-shadow: 0px 4px 3px #515151;
}

.w3-teal, .w3-teal:hover{
  background: transparent !important;
  color: white !important;
}

.listTopBar, .zone_selector, .zone_selector::after{
  background-color: #F7FFEF !important;
}

.listBar{
  background-color: #E8EAE0;
  border-radius: 10px;
}

.listBar:hover{
  background-color: #a4b291;
  border-radius: 10px;

}

.addZone{
    border: dotted;
    border-color: #969696;
    background: transparent;
    cursor: pointer;
}
.addZone .icon .icon{
 font-size: 30px;
 color: #969696;
}
.addZone .text{
 font-size: 20px;
 color: #969696;
}

.form-control,
.form-select {
  font-family: poppins_regular;
}

/* Modal Container */
.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure modal is on top */
}

/* Modal Content */
.modal-content {
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;;
  margin: auto;
}

.modal-header,
.modal-content {
  font-family: poppins_regular;
}

/* Close button */
.modal-content button {
  background-color: #6a7c59; /* Green color for button */
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content button:hover {
  background-color: #839f72; /* Darker green on hover */
}

.modal-content .view-button {
  background-color: #7c6a7f;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content .view-button:hover {
  background-color: #8c7a8f; /* Darker green on hover */
}


.image-container {
  display: flex;
  overflow: auto;
}

.preview-container {
  margin-right: 10px;
  margin-bottom: 10px;
}

.preview-image {
  width: 150px;
  height: 150px;
  object-fit: fit;
  padding: 3px;
}

.remove-button {

  top: 5px;
  right: 5px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  border: 1px solid red;
  border-radius: 50%;
  font-size: 12px;
}

.icon{
  padding-left: 10px;
  padding-right: 10px;
}

.edit-icon {
  color: #212529;
  cursor: pointer;
  
}

.delete-icon {
  color: red;
  cursor: pointer;
}

.label-left {
  text-align: left;
  width: 100%;
  display: block;
}

.modal-content .delete-button {
  background-color: #d11a1b; /* Green color for button */
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content .delete-button:hover {
  background-color: #f03949;
}

.logout_mobile{
  background: transparent;




}

.btn_logout_resp{
  background: transparent !important;
  border: none;
  color: white;
  
}
.btn_logout_resp .svg{
  background: transparent !important;
  border: none;
  color: rgb(255, 254, 254);
  text-shadow: 0px 4px 3px #515151;
  
}

.zone_selector{
  min-width: 150px;
  max-width: 150px;
}
.btn_qr{
background: transparent !important;
color: black !important;
border: none !important;
}
.bg_green{
  background-color: #4BB790 !important;
}

.logo_inQR{
  width: 40%;
  z-index: 1;
  margin-top: -25%;
  margin-left: -15%;
}

.menu_active a{
  color: rgb(255, 255, 255) !important;
  
}

.menu_active{
  background-color: #018543;
  padding-top: 3%;
  padding-bottom: 3%;
}

.dashboard_map{
  overflow: auto !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-right:5% !important;
  margin-top: 3%;
}

/* Start Dashboard Style */
#myPath, #myPath_1, #myPath_2, #myPath_3, #myPath_4, #myPath_5, #myPath_6, #myPath_7, #myPath_8,
#myPath_9, #myPath_10, #myPath_11, #myPath_12, #myPath_13, #myPath_14, #myPath_15, #myPath_16,
#myPath_17, #myPath_18, #myPath_19, #myPath_20, #myPath_21, #myPath_22, .myPath_23, #myPath_24, 
.myPath_25, #myPath_26, #myPath_27, #myPath_28, #myPath_29, #myPath_30, #myPath_31, #myPath_32,
#myPath_33, #myPath_34 {
  transition: fill 0.3s ease-in-out;
}

#myPath:hover {
  fill: #5F8A4D;
  cursor: pointer;
}
#myPath_1:hover {
  fill: #4a5d8c;
  cursor: pointer;
}
#myPath_2:hover {
  fill: #9FC667;
  cursor: pointer;
}
#myPath_3:hover {
  fill: #4c8cab;
  cursor: pointer;
}
#myPath_4:hover {
  fill: #ccc;
  cursor: pointer;
}
#myPath_5:hover {
  fill: #4F8D6D;
  cursor: pointer;
}
#myPath_6:hover {
  fill: #C660D7;
  cursor: pointer;
}
#myPath_7:hover {
  fill: #462300;
  cursor: pointer;
}
#myPath_8:hover {
  fill: #A1014D;
  cursor: pointer;
}
#myPath_9:hover {
  fill: #166f8e;
  cursor: pointer;
}
#myPath_10:hover {
  fill: #85D5C2;
  cursor: pointer;
}
#myPath_11:hover {
  fill: #073f4f;
  cursor: pointer;
}
#myPath_12:hover {
  fill: #641240;
  cursor: pointer;
}
#myPath_13:hover {
  fill: #940116;
  cursor: pointer;
}
#myPath_14:hover {
  fill: #49055B;
  cursor: pointer;
}
#myPath_15:hover {
  fill: #FFD300;
  cursor: pointer;
}
#myPath_16:hover {
  fill: #FFD539;
  cursor: pointer;
}
#myPath_17:hover {
  fill: #17D306;
  cursor: pointer;
}
#myPath_18:hover {
  fill: #7A923A;
  cursor: pointer;
}
#myPath_19:hover {
  fill: #DB9830;
  cursor: pointer;
}
#myPath_20:hover {
  fill: #515026;
  cursor: pointer;
}
#myPath_21:hover {
  fill: #9F4949;
  cursor: pointer;
}
#myPath_22:hover {
  fill: #4c0909;
  cursor: pointer;
}
.myPath_23:hover {
  fill: #388CBF;
  cursor: pointer;
}
#myPath_24:hover {
  fill: #D8B86A;
  cursor: pointer;
}
.myPath_25:hover {
  fill: #283627;
  cursor: pointer;
}
#myPath_26:hover {
  fill: #c6774e;
  cursor: pointer;
}
#myPath_27:hover {
  fill: #6a051c;
  cursor: pointer;
}
#myPath_28:hover {
  fill: #7a90b7;
  cursor: pointer;
}
#myPath_29:hover {
  fill: #57404B;
  cursor: pointer;
}
#myPath_30:hover {
  fill: #36201f;
  cursor: pointer;
}
#myPath_31:hover {
  fill: #95a39f;
  cursor: pointer;
}
#myPath_32:hover {
  fill: #3C4F47;
  cursor: pointer;
}
#myPath_33:hover {
  fill: #a0dab7;
  cursor: pointer;
}
#myPath_34:hover {
  fill: #4FA8E6;
  cursor: pointer;
}

.slick-prev,
.slick-next {
  font-size: 50px;
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255);
  /* border: none; */
  padding: 10px;
  cursor: pointer;
  z-index: 3;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: gray;
}

.pagination-container {
  position: relative;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* Adjust as needed */
}

.pagination button {
  background-color: #e0e0e0; /* Green color for button */
  color: #333;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: poppins_regular;
}

.pagination button:hover {
  background-color: #c0c0c0;
}

.pagination button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Table Styles */
.table-container {
  overflow: auto; /* Enable scrolling */
}

.table {
  width: 100%;
  border-collapse: separate;
  margin-bottom: 20px;
  background-color: #E8EAE0 !important;
  border-radius: 5px !important;
  font-family: Poppins;
}

.table th,
.table td {
  border: 0px solid #E8EAE0;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2 !important;
}

.table tr {
  background-color: #e8eae0 !important;
}

.table tr td {
  border-color: #E8EAE0;
}

thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #E8EAE0 !important;
}

.table td.text-center {
  white-space: nowrap;
}

.label_control{
  font-family: poppins_regular;
}
.table-responsive{
  overflow-x: auto;
}

.table_responsive thead th{
  background-color: #4bb790 !important;
  color: white;
  font-family: Poppins_Regular;
  border: none !important;
  white-space: nowrap;
}

.table_responsive tbody td{
 
  color: #8F9383 !important;
  white-space: nowrap;
 
}

.base_fixed{
  position: fixed;
  width: 100% !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: #900103!important;
}

@media only screen and (min-width: 501px) {
  .img_head{
    width: 95%;
    margin-left: 10%;
  }
  .nama_view {
    font-size: 25px;
    font-family: poppins_bold;
    color: #44a884;
  }
  .nama_saintifik {
    font-size: 20px;
    font-family: poppins_bold;
    color: #44a884;
  }
  .title-item {
    font-family: Poppins_Regular;
    color: #f03b22;
  }
  .title-details {
    font-family: Poppins_Regular;
  }
  .mobile-title {
    display: none;
  }
  .preview-container {
    margin-left: -10%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .img_head{
    width: 100%;
    height: 170px;
    margin-left: 5%;
  }
  .nama_view {
    font-size: 18px;
    font-family: poppins_bold;
    color: #44a884;
  }
  .nama_saintifik {
    font-size: 14px;
    font-family: poppins_bold;
    color: #44a884;
  }
  .title-item {
    font-size: 14px;
    font-family: Poppins_Regular;
    color: #f03b22;
  }
  .title-details {
    font-size: 14px;
    font-family: Poppins_Regular;
  }
  .slider_img{
    width: 200px !important;
  }
  .desktop-title{
    display: none;
  }
  .ciri-item{
    display: flex;
    justify-content: flex-end;
  }
}

.preview-profile {
  overflow: hidden;
  width: 150px;
  height: auto;
}

.preview-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
